/*
  Authors : The MESD Technology
  Website : https://mesdtech.com/
  App Name : Grocery Delivery App  This App Template Source code is licensed as per the
  terms found in the Website https://mesdtech.com/license
  Copyright and Good Faith Purchasers © 2021-present The MESD TEch.
*/
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = function () { };
  }
}

platformBrowserDynamic().bootstrapModule(AppModule, {
  useJit: true,
  preserveWhitespaces: true
})
  .catch(err => console.log(err));
