/*
  Authors : The MESD Technology
  Website : https://mesdtech.com/
  App Name : Grocery Delivery App  This App Template Source code is licensed as per the
  terms found in the Website https://mesdtech.com/license
  Copyright and Good Faith Purchasers © 2021-present The MESD TEch.
*/
import { Component } from '@angular/core';

@Component({
  templateUrl: '500.component.html'
})
export class P500Component {

  constructor() { }

}
